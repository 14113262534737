import React, { useEffect } from 'react'

const PopupQuote = ({ closePopup }) => {
  const responsive = window.innerWidth <= 920

  useEffect(() => {

    const loadScript = (src, async = true) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = async;

        script.onload = () => {
          resolve();
        };

        script.onerror = (error) => {
          reject(error);
        };

        document.body.appendChild(script);
      });
    };

    loadScript('//js.hsforms.net/forms/embed/v2.js')
      .then(() => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '23185164',
            formId: 'e25ba965-758b-43e0-a511-6e4a1f3bb69b',
            target: '#hubspot-form',
          });
        }
      })
      .catch((error) => {
        console.error('Error loading HubSpot script:', error);
      });
  }, []);

  return (
    <div className=" bg-[#12527c] h-[619px] w-[85vw] max-[1400px]:w-[75vw] max-[450px]:w-[90vw] max-[450px]:p-8 flex">

      {
        !responsive && (
          <img
            className='w-full h-full object-cover bg-[left_center]'
            src='images/bannerPopup.png'
            alt='Facture Qenta'
          />
        )
      }

      <div className='absolute flex flex-col right-10 top-8 items-center max-[920px]:static max-[920px]:right-0 max-[920px]:top-0 max-[920px]:w-full max-[920px]:p-8 max-[550px]:overflow-auto' >
        <img
          src="icons/closePopup.svg"
          alt="close"
          className="w-6 mb-6 cursor-pointer self-end max-[920px]:mb-0"
          onClick={closePopup}
        />
        <p className='text-white font-semibold text-4xl text-center max-[920px]:text-2xl'>Revolucionamos <b>tu negocio con<br />
          innovación </b> tecnológica</p>
        <div className='flex flex-col w-[31.5625vw] h-auto p-8 rounded-3xl gap-6 text-white max-[920px]:w-full max-[550px]:p-0 max-[550px]:mt-5 max-[1471px]:w-full' id='hubspot-form'>
        </div>
      </div>

    </div>
  )
}

export default PopupQuote