import { Switch } from "@mui/material";
import React, { useRef, useState } from "react";
import CustomPopup from "../../modules/CustomPopup/CustomPopup";
import PopupQuote from "../../components/PopupQuote";

const InternalPrice = () => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [hoveredPlans, setHoveredPlans] = useState(null);
  const [hoveredServices, setHoveredServices] = useState(null);
  const [hoveredRobotization, setHoveredRobotization] = useState(null);
  const [hoveredCharacteristics, setHoveredCharacteristics] = useState(null);
  const popupRef = useRef();
  // const baseUrl = window.location.origin;
  // const stringToNumber = (string) => {
  //   const noSpecialChars = string.replace(/[^a-zA-Z0-9 ]/g, "");
  //   return noSpecialChars;
  // };

  const togglePopup = () => popupRef?.current?.toggle();

  const handleOpenPopup = () => {
    togglePopup();
  };

  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleHoverCharacteristics = (id) => {
    setHoveredCharacteristics(id);
  };

  const handleHoverPlans = (id) => {
    setHoveredPlans(id);
  };

  const handleHoverServices = (id) => {
    setHoveredServices(id);
  };

  const handleHoverRobotization = (id) => {
    setHoveredRobotization(id);
  };

  const handleHoverLeave = () => {
    setHoveredPlans(null);
    setHoveredServices(null);
    setHoveredRobotization(null);
    setHoveredCharacteristics(null);
  };

  const dataPlans = [
    {
      id: 1,
      img: "facturaLiteWhite.png",
      imgBlue: "facturaLiteBlue.png",
      price: "439.000",
      description: [
        { id: 1, text: "200 documentos electrónicos / año" },
        { id: 2, text: "1 usuario" },
        { id: 3, text: "50 Cotizaciones" },
        { id: 4, text: "2 Sucursales" },
        { id: 5, text: "Certificado digital propio" },
      ],
    },
    {
      id: 2,
      img: "facturaIlimitadoWhite.png",
      imgBlue: "facturaIlimitadoBlue.png",
      price: "639.000",
      description: [
        { id: 1, text: "Documentos electrónicos ilimitados" },
        { id: 2, text: "2 Usuarios" },
        { id: 3, text: "Cotizaciones ilimitadas" },
        { id: 4, text: "3 Sucursales" },
        { id: 5, text: "Certificado digital propio" },
      ],
    },
    {
      id: 3,
      img: "qentaLiteWhite.png",
      imgBlue: "qentaLiteBlue.png",
      price: "919.000",
      description: [
        { id: 1, text: "200 documentos electrónicos / año" },
        { id: 2, text: "2 Usuarios" },
        { id: 3, text: "50 Cotizaciones" },
        { id: 4, text: "5 Sucursales" },
        { id: 5, text: "Certificado digital propio" },
      ],
    },
    {
      id: 4,
      img: "qentaIlimitadoWhite.png",
      imgBlue: "qentaIlimitadoBlue.png",
      price: "1.200.000",
      description: [
        { id: 1, text: "Documentos electrónicos ilimitados" },
        { id: 2, text: "Usuarios ilimitados" },
        { id: 3, text: "Cotizaciones ilimitadas" },
        { id: 4, text: "Sucursales ilimitadas" },
        { id: 5, text: "Certificado digital propio" },
        { id: 6, text: "Contabilidad automatizada" },
        { id: 7, text: "Creación de inventarios ilimitados" },
        { id: 8, text: "Reportes" },
      ],
    },
  ];

  const dataServicesAditionals = [
    {
      id: 1,
      title: "Inventarios",
      price: "200.000",
      desciptionPrice: "año",
      description: [
        {
          id: 1,
          title: "",
          text: "Administra tu inventario ilimitadamente.",
        },
        {
          id: 2,
          title: "",
          text: "Registra y controla tus activos con nuestra herramienta simplificada.",
        },
      ],
    },
    {
      id: 2,
      title: "Emisión de nómina, DSA y Recepción",
      price: "150.000",
      desciptionPrice: "(120 Doc / año)",
      description: [
        {
          id: 1,
          title: "Documento soporte:",
          text: "Emite a la DIAN y respalda compras a personas naturales no obligadas a facturar.",
        },
        {
          id: 2,
          title: "Recepción:",
          text: "Recibe facturas electrónicas y gestiona eventos fácilmente en nuestro sistema de recepción.",
        },
      ],
    },
    {
      id: 3,
      title: "Capacitación adicional",
      price: "102.000",
      desciptionPrice: "(2 horas virtual)",
      description: [
        {
          id: 1,
          title: "Capacitación personalizada:",
          text: "Se adapta a tu tipo de suscripción.",
        },
        {
          id: 2,
          title: "Agendamiento único:",
          text: "Coordina con el equipo de capacitación; la fecha y hora son fijas.",
        },
      ],
    },
    {
      id: 4,
      title: "Usuario adicional",
      price: "90.000",
      desciptionPrice: "año",
      description: [
        {
          id: 1,
          title: "",
          text: "Añade usuarios adicionales a tu suscripción por un año y establece sus permisos según tu elección como propietario de la suscripción.",
        },
      ],
    },
  ];

  const dataRobotization = [
    {
      id: 1,
      title: "Rut Electrónico y Fac. Electrónica 2.0",
      price: "150.000",
      description: [
        {
          id: 1,
          text: "Reduce la operatividad.",
        },
        {
          id: 2,
          text: "Automatización y cero errores en la creación.",
        },
        {
          id: 3,
          text: "Clientes Documenta el 100% los datos del cliente para medios magnéticos.",
        },
        {
          id: 4,
          text: "Cero errores en la contabilización.",
        },
        {
          id: 5,
          text: "Facturar de manera ágil.",
        },
        {
          id: 6,
          text: "Generar informes de impuestos por pagar (IVA e ICA).",
        }
      ],
    },
    // {
    //   id: 2,
    //   title: "Facturación Electrónica 2.0",
    //   price: "69.000",
    //   description: [
    //     {
    //       id: 1,
    //       text: "Cero errores en la contabilización.",
    //     },
    //     {
    //       id: 2,
    //       text: "Facturar de manera ágil.",
    //     },
    //     {
    //       id: 3,
    //       text: "Generar informes de impuestos por pagar (IVA e ICA).",
    //     },
    //   ],
    // },
  ];

  const characteristics = [
    {
      id: 1,
      text: "Certificado digital propio",
    },
    {
      id: 2,
      text: "Usuarios",
    },
    {
      id: 3,
      text: "Sucursales",
    },
    {
      id: 4,
      text: "Ingresos",
    },
    {
      id: 5,
      text: "Facturas electróncias",
    },
    {
      id: 6,
      text: "Notas debito y credito",
    },
    {
      id: 7,
      text: "Nómina eléctronica (emisión)",
    },
    {
      id: 8,
      text: "Ajustes de nómina electrónica",
    },
    {
      id: 9,
      text: "Documento Soporte",
    },
    {
      id: 10,
      text: "Ajustes de documento soporte",
    },
    {
      id: 11,
      text: "Eventos de recepción",
    },
    {
      id: 12,
      text: "Cotizaciones",
    },
    {
      id: 13,
      text: "Centros de costo",
    },
    {
      id: 14,
      text: "Cartera clientes y proveedores",
    },
    {
      id: 15,
      text: "Compras y gastos",
    },
    {
      id: 16,
      text: "Cajas y bancos",
    },
    {
      id: 17,
      text: "Creación de conceptos de ventas",
    },
    {
      id: 18,
      text: "Movimientos contables",
    },
    {
      id: 19,
      text: "Estado financiero y balance general",
    },
    {
      id: 20,
      text: "Estado de documentos electrónicos",
    },
    {
      id: 21,
      text: "Consecutivo Facturas de Venta",
    },
    {
      id: 22,
      text: "Detalles de movimientos contables",
    },
    {
      id: 23,
      text: "Libros auxiliares",
    },
    {
      id: 24,
      text: "Medios magneticos o información exógena",
    },
    {
      id: 25,
      text: "Certificado de renta",
    },
    {
      id: 26,
      text: "Impuestos y retenciones",
    },
    {
      id: 27,
      text: "Bodegas",
    },
    {
      id: 28,
      text: "Creación masiva de líneas y productos",
    },
    {
      id: 29,
      text: "Costeo de Productos",
    },
    {
      id: 30,
      text: "Control de existencias",
    },
    {
      id: 31,
      text: "Informes de inventarios",
    },
  ];

  const characteristicPlans = [
    {
      id: 1,
      title: "Factura GO Lite",
      price: "439.000",
      description: [
        {
          id: 1,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 2,
          text: "1",
        },
        {
          id: 3,
          text: "2",
        },
        {
          id: 4,
          text: "Sin límite de cuantía",
        },
        {
          id: 5,
          img: "images/characteristicLiteWhite.png",
        },
        {
          id: 6,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 7,
          text: "50",
        },
        {
          id: 8,
          text: "1",
        },
        {
          id: 9,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 10,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 11,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 12,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 13,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 14,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 15,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 16,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 17,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 18,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 19,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 20,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 21,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 22,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 23,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 24,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 25,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 26,
          img: "images/iconCloseBlue.png",
        },
      ],
    },
    {
      id: 2,
      title: "Factura GO Ilimitado",
      price: "639.000",
      description: [
        {
          id: 1,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 2,
          text: "2",
        },
        {
          id: 3,
          text: "3",
        },
        {
          id: 4,
          text: "Sin límite de cuantía",
        },
        {
          id: 5,
          img: "images/characteristicUnlimitedWhite.png",
        },
        {
          id: 6,
          text: "Ilimitado",
        },
        {
          id: 7,
          text: "Ilimitado",
        },
        {
          id: 8,
          text: "1",
        },
        {
          id: 9,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 10,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 11,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 12,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 13,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 14,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 15,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 16,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 17,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 18,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 19,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 20,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 21,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 22,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 23,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 24,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 25,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 26,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 27,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 28,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 29,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 30,
          img: "images/iconCloseBlue.png",
        },
      ],
    },
    {
      id: 3,
      title: "Q·enta Go Lite",
      price: "919.000",
      description: [
        {
          id: 1,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 2,
          text: "2",
        },
        {
          id: 3,
          text: "5",
        },
        {
          id: 4,
          text: "Sin límite de cuantía",
        },
        {
          id: 5,
          img: "images/characteristicLiteWhite.png",
        },
        {
          id: 6,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 7,
          text: "50",
        },
        {
          id: 8,
          text: "2",
        },
        {
          id: 9,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 10,
          text: "Ilimitado",
        },
        {
          id: 11,
          text: "Ilimitado",
        },
        {
          id: 12,
          text: "Ilimitado",
        },
        {
          id: 13,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 14,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 15,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 16,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 17,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 18,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 19,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 20,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 21,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 22,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 23,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 24,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 25,
          img: "images/iconCloseBlue.png",
        },
        {
          id: 26,
          img: "images/iconCloseBlue.png",
        },
      ],
    },
    {
      id: 4,
      title: "Q·enta Go Ilimitado",
      price: "1.200.000",
      description: [
        {
          id: 1,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 2,
          text: "Ilimitado",
        },
        {
          id: 3,
          text: "Ilimitado",
        },
        {
          id: 4,
          text: "Sin límite de cuantía",
        },
        {
          id: 5,
          img: "images/characteristicUnlimitedWhite.png",
        },
        {
          id: 6,
          text: "Ilimitado",
        },
        {
          id: 7,
          text: "Ilimitado",
        },
        {
          id: 8,
          text: "Ilimitado",
        },
        {
          id: 9,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 10,
          text: "Ilimitado",
        },
        {
          id: 11,
          text: "Ilimitado",
        },
        {
          id: 12,
          text: "Ilimitado",
        },
        {
          id: 13,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 14,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 15,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 16,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 17,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 18,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 19,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 20,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 21,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 22,
          text: "Ilimitado",
        },
        {
          id: 23,
          text: "Ilimitado",
        },
        {
          id: 24,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 25,
          img: "icons/checkCircleGreen.svg",
        },
        {
          id: 26,
          img: "icons/checkCircleGreen.svg",
        },
      ],
    },
  ];

  const responsiveCharacteristics = window.innerWidth <= 960;

  const handleDownload = () => {
    const fileUrl = "/files/CuadroComparativoCaracteristicas.pdf";
    const anchor = document.createElement("a");

    anchor.href = fileUrl;
    anchor.download = "Planes_Caracteristicas.pdf";
    anchor.click();
  };

  return (
    <div className="w-screen h-screen px-[11.9792vw] font-catamara flex flex-col items-center">
      <h1 className="font-semibold text-[40px] text-[#3F239A] mb-3 text-center">
        Bienvenido a la revolución Tecnológica contable
      </h1>
      <p className="text-2xl text-center font-medium text-black ">
        Innovamos para darte más soluciones y tecnología que te liberen de la
        operatividad y te ayuden a optimizar tiempo.{" "}
        <b>Conoce nuestras nuevas suscripciones.</b>
      </p>

      <div className="w-full h-auto flex flex-col mt-12  mb-12 items-center justify-center">
        <div className="flex gap-4 text-2xl max-[550px]:text-lg items-center justify-center">
          <p
            className={`${
              isSwitchOn ? "text-[#A3A3A3]" : "text-[#3F239A]"
            } font-semibold`}
          >
            Planes
          </p>
          <Switch
            checked={isSwitchOn}
            onChange={handleSwitchChange}
            sx={{
              "& .MuiSwitch-track": {
                bgcolor: "#3F239A !important",
              },
              "& .MuiSwitch-thumb": {
                bgcolor: "#fff",
              },
              "& .Mui-checked": {
                color: "#3F239A !important",
              },
            }}
          />
          <p
            className={`${
              !isSwitchOn ? "text-[#A3A3A3]" : "text-[#3F239A]"
            } font-semibold`}
          >
            Características
          </p>
        </div>
        {isSwitchOn ? (
          responsiveCharacteristics ? (
            <div className=" w-full h-auto flex flex-col mt-12 gap-6  max-[1550px]:mt-10">
              <p className="text-lg font-semibold text-center">
                Haz clic en el botón de descarga para ver toda la información de
                nuestros fantásticos planes. ¡Elige la suscripción a tú medida!
                🚀{" "}
              </p>
              <button
                className="bg-[#3F239A] text-white font-semibold text-lg h-14 w-auto rounded-3xl flex items-center justify-center gap-4"
                onClick={handleDownload}
              >
                Descargar información{" "}
                <img
                  src="icons/downloadFile.svg"
                  alt="download"
                  className="w-6"
                />{" "}
              </button>
            </div>
          ) : (
            <div className="w-full h-auto flex items-center justify-center mt-12  transition-transform transform max-[1550px]:scale-75 max-[1550px]:m-0 max-[1380px]:scale-[0.68] max-[1290px]:scale-[0.6] max-[1290px]:-mt-52 max-[1290px]:-mb-72 max-[1120px]:scale-50 max-[1120px]:-mt-72 max-[1120px]:-mb-96 scale-[0.95] max-[1550px]:-mb-52 max-[1550px]:-mt-32">
              <div className="w-[450px] flex flex-col justify-between h-[1095px] mt-20 ">
                {characteristics.map((characteristic) => {
                  return (
                    <div className=" w-[450px] flex-col flex px-10 text-[#3F239A]">
                      <p
                        key={characteristic.id}
                        className="text-lg font-semibold text-[#3F239A] whitespace-nowrap"
                      >
                        {characteristic.text}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="h-[1143px] border border-[rgba(63,35,154,0.5)] mt-20" />
              <div className="flex">
                {characteristicPlans.map((plan, index) => {
                  return (
                    <div
                      key={index}
                      className="w-[369px] flex flex-col rounded-xl justify-between items-center h-[1405px] pt-9 pb-8 px-12 text-[#3F239A] transition-transform transform hover:border-2 hover:bg-[#3F239A] hover:text-[#DEF6FF] hover:z-10 hover:justify-between "
                      onMouseEnter={() => handleHoverCharacteristics(plan.id)}
                      onMouseLeave={handleHoverLeave}
                    >
                      <div className="flex flex-col items-center justify-center gap-4 ">
                        <p className="text-2xl font-bold text-center">
                          {plan.title}
                        </p>
                        <div className="flex flex-col items-center mb-">
                          <p className="text-[36px] font-bold ">
                            $<b className="text-4xl">{plan.price}</b>
                          </p>
                          <p className="text-xl font-bold">año</p>
                        </div>
                      </div>

                      <div
                        className={`flex flex-col items-center h-[1095px] justify-between`}
                      >
                        {plan.description.map((item) => {
                          const isHover = hoveredCharacteristics === plan.id;
                          const checkCircle =
                            item.img === "icons/checkCircleGreen.svg";
                          const iconClose =
                            item.img === "images/iconCloseBlue.png";
                          const imageCharacteristicLite =
                            item.img === "images/characteristicLiteWhite.png";
                          const imageCharacteristicUnlimited =
                            item.img ===
                            "images/characteristicUnlimitedWhite.png";

                          const imageHoveredCharacteristic =
                            hoveredCharacteristics &&
                            imageCharacteristicLite &&
                            isHover;
                          const imageHoveredCharacteristicUnlimited =
                            hoveredCharacteristics &&
                            imageCharacteristicUnlimited &&
                            isHover;

                          const closeCircleHovered =
                            hoveredCharacteristics && iconClose && isHover;
                          const checkCircleHovered =
                            hoveredCharacteristics && checkCircle && isHover;

                          return (
                            <div className="flex items-center" key={item.id}>
                              {item.img ? (
                                <img
                                  src={
                                    checkCircleHovered
                                      ? "icons/checkCircleWhite.svg"
                                      : closeCircleHovered
                                      ? "images/iconCloseWhiteBlue.png"
                                      : imageHoveredCharacteristic
                                      ? "images/characteristicLiteBlue.png"
                                      : imageHoveredCharacteristicUnlimited
                                      ? "images/characteristicUnlimitedBlue.png"
                                      : item.img
                                  }
                                  className="w-auto "
                                  alt="check"
                                  style={{ fill: "blue" }}
                                />
                              ) : (
                                <p className="text-lg font-bold">{item.text}</p>
                              )}
                            </div>
                          );
                        })}
                      </div>

                      <button
                        className={`w-full h-14 text-2xl font-semibold rounded-2xl ${
                          hoveredCharacteristics === plan.id
                            ? "text-[#3F239A] bg-[#DEF6FF]"
                            : "text-white bg-white"
                        }`}
                        onClick={handleOpenPopup}
                      >
                        Cotizar ahora
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        ) : (
          <div
            className={`w-full h-auto flex items-center justify-center mt-12 flex-row transition-transform transform scale-[0.85] max-[920px]:flex-col max-[920px]:gap-4 max-[1166px]:scale-[0.7] max-[1166px]:mt-6 max-[1166px]:-mb-16 max-[920px]:mt-10 max-[920px]:scale-100`}
          >
            {dataPlans.map((plan) => {
              return (
                <div
                  key={plan.id}
                  className="w-[426px] h-[604px] max-[550px]:w-[340px] bg-[#3F239A] border-white border-[3px] rounded-xl flex flex-col justify-between gap-4 px-10 pt-16 pb-8 transition-transform transform hover:scale-105 hover:border-2 hover:border-white hover:bg-[#DEF6FF] hover:z-10"
                  onMouseEnter={() => handleHoverPlans(plan.id)}
                  onMouseLeave={handleHoverLeave}
                >
                  <img
                    src={`/images/${
                      hoveredPlans === plan.id ? plan.imgBlue : plan.img
                    }`}
                    alt="qentaGoLite"
                    className="w-[220px] self-center"
                  />
                  <p
                    className={`text-2xl self-center font-medium ${
                      hoveredPlans === plan.id
                        ? "text-[#3F239A]"
                        : "text-[#DEF6FF]"
                    }`}
                  >
                    $
                    <b className="text-4xl max-[550px]:text-3xl max-[550px]:font-bold">
                      {plan.price}
                    </b>{" "}
                    año
                  </p>
                  <div className="flex flex-col self-start gap-1">
                    {plan.description.map((item) => {
                      return (
                        <div className="flex items-center " key={item.id}>
                          <img
                            src={`${
                              hoveredPlans === plan.id
                                ? "images/checkCircleBlue.png"
                                : "icons/checkCircleOutlineWhite.svg"
                            }`}
                            className="w-3 h-3 mr-2"
                            alt="check"
                          />
                          <p
                            key={item.id}
                            className={`text-sm  ${
                              hoveredPlans === plan.id
                                ? "text-[#3F239A]"
                                : "text-[#DEF6FF]"
                            } `}
                          >
                            {item.text}
                          </p>
                        </div>
                      );
                    })}
                  </div>

                  <button
                    className={` font-semibold px-4 py-2 rounded-2xl w-60 h-14 text-2xl self-center transition-all ${
                      hoveredPlans === plan.id
                        ? "bg-[#582ED9] text-[#DEF6FF]"
                        : "bg-[#DEF6FF] text-[#582ED9]"
                    }`}
                    onClick={handleOpenPopup}
                  >
                    Cotizar ahora
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="flex flex-col mt-12 mb-24 w-full items-center">
        <p className="text-[#3F239A] text-[40px] font-semibold text-6xl mb-16 self-center text-center max-[550px]:text-4xl ">
          Complementa tu suscripción
        </p>
        <p className="text-2xl mb-12 text-center text-black font-medium">
          <b>¿Necesitas más?</b> Tranquilo en Q·enta puedes solicitar{" "}
          <b>servicios adicionales</b> cuándo los necesites
        </p>

        <div
          className={`w-full h-auto flex items-center justify-center flex-row max-[920px]:flex-col max-[920px]:gap-4 scale-[0.85] max-[920px]:scale-100`}
        >
          {dataServicesAditionals.map((service) => {
            return (
              <div
                key={service.id}
                className="w-[426px] h-[480px] max-[550px]:w-[340px] pt-10 pb-8 bg-[#3F239A] border-white border rounded-xl flex flex-col justify-around gap-4 px-10 text-white transition-transform transform hover:scale-105 hover:border-2 hover:border-white hover:bg-[#DEF6FF] hover:text-[#DEF6FF] hover:z-10"
                onMouseEnter={() => handleHoverServices(service.id)}
                onMouseLeave={handleHoverLeave}
              >
                <p
                  className={`font-semibold text-2xl self-center text-center ${
                    hoveredServices === service.id
                      ? "text-[#3F239A]"
                      : "text-[#8ADEBD]"
                  }`}
                >
                  {service.title}
                </p>
                <p
                  className={`text-[20px] self-center font-medium ${
                    hoveredServices === service.id
                      ? "text-[#3F239A]"
                      : "text-[#8ADEBD]"
                  }`}
                >
                  $<b className="text-3xl font-bold">{service.price}</b> {service.desciptionPrice}
                </p>
                <div className="flex flex-col self-start gap-2">
                  {service.description.map((item) => {
                    return (
                      <div className="flex items-start" key={item.id}>
                        <img
                          src={
                            hoveredServices === service.id
                              ? "images/checkCircleBlue.png"
                              : "icons/checkCircleOutlineWhite.svg"
                          }
                          className="w-3 h-3 mr-2 mt-1"
                          alt="check"
                        />
                        <div className="flex flex-col">
                          <p
                            key={item.id}
                            className={`text-sm font-bold ${
                              hoveredServices === service.id
                                ? "text-[#3F239A]"
                                : "text-[#DEF6FF]"
                            }`}
                          >
                            {item.title}
                          </p>

                          <p
                            key={item.id}
                            className={`text-sm ${
                              hoveredServices === service.id
                                ? "text-[#3F239A]"
                                : "text-[#DEF6FF]"
                            }`}
                          >
                            {item.text}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <a
                  href={`https://api.whatsapp.com/send?phone=573225759517&text=!Hola%20%F0%9F%91%8B%20%C2%A1%20Estoy%20interesado%20en%20Q%C2%B7enta%20...`}
                  className="w-auto flex justify-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className={`font-semibold px-4 py-2 rounded-2xl w-60 h-14 text-2xl self-center transition-all ${
                      hoveredServices === service.id
                        ? "bg-[#3F239A] text-[#DEF6FF]"
                        : "bg-[#8ADEBD] text-[#3F239A] "
                    }`}
                  >
                    Comprar
                  </button>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col mb-4 w-full">
        <p className="text-[#3F239A] font-semibold text-4xl mb-8 self-center text-center">
          Robotización contable
        </p>
        <p className="text-2xl text-black font-medium mb-16 self-center text-center">
          Asómbrate con dos robots de alta tecnología trabajando para ti{" "}
        </p>

        <div
          className={`w-full h-auto flex items-center justify-center  flex-row max-[920px]:flex-col max-[920px]:gap-4`}
        >
          {dataRobotization.map((robotization) => {
            return (
              <div
                key={robotization.id}
                className="w-[426px] h-[475px] max-[550px]:w-[340px] bg-[#3F239A] rounded-xl flex flex-col border-white border justify-around gap-4 px-10 pt-10 pb-9 text-[#EABDFF] transition-transform transform hover:scale-105 hover:border-2 hover:border-white hover:bg-[#DEF6FF] hover:text-[#3F239A] hover:z-10"
                onMouseEnter={() => handleHoverRobotization(robotization.id)}
                onMouseLeave={handleHoverLeave}
              >
                <p className="font-semibold text-2xl self-center text-center">
                  {robotization.title}
                </p>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-center">
                    <p className="text-[20px] self-center font-medium">
                      $<b className="text-[36px]">{robotization.price}</b> año
                    </p>
                    {/* <img
                      src={
                        hoveredRobotization === robotization.id
                          ? "images/iconArrowBlue.png"
                          : "images/iconArrowFucsia.png"
                      }
                      alt="robot"
                      className="w-7 h-7 self-center"
                    /> */}
                  </div>
                </div>

                <div className="flex flex-col self-start gap-2">
                  {robotization.description.map((item) => {
                    return (
                      <div className="flex items-center" key={item.id}>
                        <img
                          src={
                            hoveredRobotization === robotization.id
                              ? "images/checkCircleBlue.png"
                              : "icons/checkCircleOutlineWhite.svg"
                          }
                          className="w-3 h-3 mr-2"
                          alt="check"
                        />
                        <p
                          key={item.id}
                          className={`text-sm  ${
                            hoveredRobotization === robotization.id
                              ? "text-[#3F239A]"
                              : "text-[#DEF6FF]"
                          }`}
                        >
                          {item.text}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <button
                  className={`font-semibold px-4 py-2 rounded-2xl w-60 h-14 text-2xl self-center transition-all ${
                    hoveredRobotization === robotization.id
                      ? "bg-[#582ED9] text-[#DEF6FF]"
                      : " bg-[#EABDFF] text-[#3F239A]"
                  }`}
                  onClick={handleOpenPopup}
                >
                  Solicitar Demo
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <CustomPopup
        customRef={popupRef}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <PopupQuote closePopup={togglePopup} />
      </CustomPopup>
    </div>
  );
};

export default InternalPrice;
